import Vue from "vue";
import { format, formatRelative, parseISO } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";

Vue.filter("dateTimeRelative", function (value) {
  if (value == null) {
    return "";
  }

  const date = parseISO(value);

  return formatRelative(date, new Date(), { locale: ptBR });
});

Vue.filter("dateBR", function (value) {
  if (value) {
    return format(new Date(value), "dd/MM/yyyy");
  }

  return "";
});

//Filter CNPJ
Vue.filter("cnpj", function (value) {
  if (value) {
    return value
      .toString()
      .replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5");
  }
  return "";
});

//Filter PHONE
Vue.filter("phone", function (value) {
  if (value) {
    value = value.replace(/^\d{2}/, '');
    value = value.replace(/D/g, ""); //Remove tudo o que não é dígito
    value = value.replace(/^(d{2})(d)/g, "($1) $2"); //Coloca parênteses em volta dos dois primeiros dígitos
    value = value.replace(/(d)(d{4})$/, "$1-$2"); //Coloca hífen entre o quarto e o quinto dígitos
    return value;
  }

  return null;
});

export function moneyBr(value) {
  if (!value) {
    var value = 0;
  }

  var parsedValue = parseFloat(value);
  var isNegative = parsedValue < 0;
  var value = Math.abs(parsedValue).toFixed(2).split(".");

  value[0] = value[0].split(/(?=(?:...)*$)/).join(".");

  return "R$ " + (isNegative ? "-" : "") + value.join(",");
}

//Filter Dinheiro BR
Vue.filter("moneyBr", moneyBr);

//Filter Dinheiro BR
Vue.filter("money", function (value) {
  if (!value) {
    var value = 0;
  }

  var parsedValue = parseFloat(value);
  var value = parsedValue.toFixed(2).split(".");

  value[0] = value[0].split(/(?=(?:...)*$)/).join(".");

  return value.join(",");
});

//Filter CPF
Vue.filter("cpf", function (value) {
  if (value) {
    return value
      .toString()
      .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
  }

  return "";
});

//Filter CEP
Vue.filter("cep", function (value) {
  if (value) {
    value = value.toString().replace(/(\d{5})(\d{3})/g, "$1-$2");
    return value;
  }

  return "";
});

Vue.filter("limiter", function (value = "") {
  if (value) {
    if (window.innerWidth <= 600 && value.length > 10) {
      return value.substring(0, 10) + "...";
    }
  }
  return value;
});

const months = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];
const days = [
  "Domingo",
  "Segunda",
  "Terça",
  "Quarta",
  "Quinta",
  "Sexta",
  "Sábado",
];

Vue.filter("readerDate", function (date) {
  let procDate = date ? new Date(date) : new Date();
  return (
    days[procDate.getDay()] +
    ", " +
    procDate.getDate() +
    " de " +
    months[procDate.getMonth()] +
    " - " +
    procDate.getFullYear()
  );
});

Vue.filter("toCamelCase", function (str) {
  return str
    .toLowerCase() 
    .split(/[\s-_]+/) 
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
});
