import Vue from 'vue'
import Auth from '../auth/Auth'

Vue.use({
  install(Vue) {
    const options = {
      urls: {
        login: 'auth/login',
        logout: 'auth/logout',
        loggedUser: 'auth/me',
      },
      redirect: {
        home: '/',
        login: '/login',
      },
    }

    const auth = new Auth(options)
    Vue.prototype.$auth = auth
    Vue.$auth = auth
  },
})

export default (_) => { }
