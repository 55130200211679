import Vue from "vue";

export default {
  namespaced: true,

  state: () => ({
    codeServices: [],
  }),

  mutations: {
    setCodeServices(state, data) {
      state.codeServices = data;
    },
  },

  actions: {
    getCodeServices({ commit, state }) {
        Vue.$http
        .index("/code-service")
        .then((response) => {
          commit("setCodeServices", response.data.data);
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
  },
};
