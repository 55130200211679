import Vue from 'vue';
import VueRouter from 'vue-router';
import authMiddleware from './middlewares/authMiddleware';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'home',
  },
  {
    path: '/impersonate',
    component: () => import(/* webpackChunkName: "Dashboard" */'@/views/Impersonate.vue'),
    meta: {
      layout: 'blank', auth: 'guest'
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "Dashboard" */'@/views/dashboard/Crm.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "Home" */'@/views/dashboard/Crm.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/produtos',
    name: 'products',
    component: () => import(/* webpackChunkName: "Product" */'@/views/Products/Products.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/clientes',
    name: 'consumers',
    component: () => import(/* webpackChunkName: "Customers" */'@/views/Customers/Customers.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/fornecedores',
    name: 'suppliers',
    component: () => import(/* webpackChunkName: "Suppliers" */'@/views/Suppliers/Suppliers.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/vendas',
    name: 'orders',
    component: () => import(/* webpackChunkName: "Orders" */'@/views/Orders/Orders.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/vendas/detalhes/:id',
    name: 'ordersDetails',
    component: () => import(/* webpackChunkName: "OrdersDetails" */'@/views/Orders/OrderDetails.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/vendas/importar',
    name: 'importOrders',
    component: () => import(/* webpackChunkName: "ImportOrders" */'@/views/Orders/ImportOrders.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/vendas/sincronizar',
    name: 'sincronizarOrders',
    component: () => import(/* webpackChunkName: "SyncOrdersPlatforms" */'@/components/orders/form/SyncOrdersPlatforms.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/documentos',
    name: 'documents',
    component: () => import(/* webpackChunkName: "Documents" */'@/views/Documents/Documents.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/integracoes',
    name: 'integrations',
    component: () => import(/* webpackChunkName: "integrations" */'@/views/Integrations/Integrations.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/notas-de-entrada',
    name: 'incoming-invoices',
    component: () => import(/* webpackChunkName: "IcommingInvoices" */'@/views/Invoices/IcommingInvoices.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/notas-de-saida',
    name: 'outgoing-invoices',
    component: () => import(/* webpackChunkName: "OutgoingInvoices" */'@/views/Invoices/OutgoingInvoices.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/notas-de-produto',
    name: 'products-invoices',
    component: () => import(/* webpackChunkName: "ProductsInvoices" */'@/views/Invoices/ProductsInvoices.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/contas',
    name: 'accounts',
    component: () => import(/* webpackChunkName: "Accounts" */'@/views/Financial/Accounts.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/lancamentos',
    name: 'cash-moviments',
    component: () => import(/* webpackChunkName: "CashMoviments" */'@/views/Financial/CashMoviments.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/fluxo-de-caixa',
    name: 'cash-flow',
    component: () => import(/* webpackChunkName: "CashFlow" */'@/views/Financial/CashFlow.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/minha-empresa',
    name: 'my-company',
    component: () => import(/* webpackChunkName: "Company" */'@/views/Administration/Company.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/logisticas',
    name: 'logistics',
    component: () => import(/* webpackChunkName: "Logistics" */'@/views/Transport/Logistics.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/galpoes',
    name: 'storages',
    component: () => import(/* webpackChunkName: "Company" */'@/views/Transport/Storages.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/packages',
    name: 'packages',
    component: () => import(/* webpackChunkName: "Company" */'@/views/Transport/Packages.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/usuarios',
    name: 'users',
    component: () => import(/* webpackChunkName: "Users" */'@/views/Administration/Users.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/perfis',
    name: 'roles',
    component: () => import(/* webpackChunkName: "Roles" */'@/views/Roles/Roles.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/agenda',
    name: 'calendar',
    component: () => import(/* webpackChunkName: "Calendars" */'@/views/Calendar/Calendar.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/faq',
    name: 'faq',
    // component: () => import(/* webpackChunkName: "Faq" */'@/views/Faq/Faq.vue'),
    meta: {
      layout: 'content',
    },
  },


  {
    path: '/esqueci-minha-senha',
    name: 'forgot-password',
    component: () => import('@/views/ForgotPassword.vue'),
    meta: {
      layout: 'blank', auth: 'guest',
    },
  },
  {
    path: '/auth/reset-password',
    name: 'reset-password',
    component: () => import('@/views/ResetPassword.vue'),
    meta: {
      layout: 'blank', auth: 'guest',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Auth/Signin.vue'),
    meta: {
      layout: 'blank', auth: 'guest',
    },
  },
  {
    path: '/signup',
    name: 'auth-signup',
    component: () => import('@/views/Auth/Signup.vue'),
    meta: {
      layout: 'blank', auth: 'guest'

    },
  },
  {
    path: '/iluminado',
    name: 'auth-signup2',
    component: () => import('@/views/Auth/Signup2.vue'),
    meta: {
      layout: 'blank', auth: 'guest'

    },
  },
  {
    path: "*",
    name: "404",
    meta: { layout: 'blank' },
    component: () => import('@/views/Error404.vue'),
  },
  {
    path: "/subscribed",
    name: "402",
    meta: { layout: 'blank' },
    component: () => import('@/views/PaymentDue402.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

router.beforeEach(async (to, from, next) => {
  authMiddleware(to, from, next)
})

export default router
