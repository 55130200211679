import Vue from "vue";

export default {
  namespaced: true,

  state: () => ({
    company: null,
    contract: null,
  }),

  mutations: {
    setCompany(state, data) {
      state.user = data;
    },

    setContract(state, data) {
      state.contract = data;
    },
  },

  actions: {
    createContract({ commit }, params) {
      return Vue.$http.store("/create-contract", params).then((response) => {
        commit("setContract", response.data);
        return response.data;
      });
    },
  },
};
