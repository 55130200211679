<template>
  <v-badge
  :content="notifications.length"
  :value="notifications"
  overlap
  color="accent"
  >
    <v-menu
      offset-y
      left
      nudge-bottom="22"
      :elevation="$vuetify.theme.dark ? 9 : 8"
      content-class="list-style notification-menu-content"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-bind="attrs" v-on="on">{{ icons.mdiBellOutline }}</v-icon>
      </template>
      <v-card class="app-bar-notification-content-container">
        <perfect-scrollbar class="ps-user-notifications" :options="perfectScrollbarOptions">
          <v-list class="py-0">
            <!-- Header -->
            <v-list-item class="d-flex" link>
              <div class="d-flex align-center justify-space-between flex-grow-1">
                <span class="font-weight-semibold">Notificações</span>
                <v-chip class="v-chip-light-bg primary--text font-weight-semibold" small v-if="notifications.length">{{ notifications.length }} Notificações</v-chip>
              </div>
            </v-list-item>
            <v-divider></v-divider>

            <!-- Notifications -->
            <template v-for="(notification, index) in notifications"  >
              <v-list-item :key="notification.index" link @click="goToNotification(notification)" class="mb-4">
                <v-list-item-icon class="ma-2 mx-0" style="d-flex align-center">
                  <v-icon>
                    {{ notificationIcons[notification.data.status] }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content class="ml-4">
                  <v-list-item-title>
                    {{ notification.data.message }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                      <span :style="{color: notification.data.status === 'order' ? '#56CA00' : 'white'}">
                        {{ notification.data.value | moneyBr }}
                      </span>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <span class="text--secondary text-xs">{{ notification.updated_at | dateTimeRelative }}</span>
                </v-list-item-action>
              </v-list-item>
              <v-divider :key="index"></v-divider>
            </template>
            <div v-if="notifications.length == 0" class="d-flex justify-center align-center ma-2">
              <span>
                Sem notificações no momento.
              </span>
            </div>
            <v-list-item key="read-all-btn" class="read-all-btn-list-item">
              <v-btn block color="primary">Marcar todas como lidas</v-btn>
            </v-list-item>
          </v-list>
        </perfect-scrollbar>
      </v-card>
    </v-menu>
  </v-badge>

</template>

<script>
  import { mdiBellOutline, mdiClose, mdiShopping, mdiShoppingOutline, mdiShoppingSearch } from '@mdi/js'
  import { getInitialName } from '@core/utils'
  import Filter from '@/util/Filters'

  import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
  export default {
    components: {
      // 3rd Party
      PerfectScrollbar,
    },

    data() {
      return {

        notificationIcons: {
          'draft': mdiShoppingSearch,
          'pendding': mdiShoppingOutline, 
          'order': mdiShopping,
          "cancelled": mdiClose
          
        },
        notifications: []
      }
    }, //

    mounted() {
     this.$http.index('/notification')
     .then(response => {
      this.notifications = response
     })
     .catch()
    },

    setup() {
      const perfectScrollbarOptions = {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      }

      return {
        getInitialName,
        perfectScrollbarOptions,
        icons: {
          mdiBellOutline,
        },
      }
    },

    methods:{
      // Falta implementar
      goToNotification(e){
        // console.log(e);
      },

      resolveUpdateDate(updated_at){
        let minutes = (Date.now() - new Date(updated_at)) / (1000 * 60)
        if(minutes > 1440){
          return Math.ceil((minutes / 60 / 24))  + ' dias atrás.'
        }
        if(minutes > 60){
          return Math.ceil((minutes / 60)) + ' horas atrás.'
        }
        return minutes + ' minutos atrás.'
      }
    }
  }
</script>

<style lang="scss">
@import "~vuetify/src/styles/styles.sass";

.app-bar-notification-content-container {
  .read-all-btn-list-item {
    padding-top: 14px;
    padding-bottom: 14px;
    min-height: unset;
  }
}

.ps-user-notifications {
  max-height: calc(var(--vh, 1vh) * 80);
}

.notification-menu-content {
  @media #{map-get($display-breakpoints, 'xs-only')} {
    min-width: calc(100vw - (1.5rem * 2)) !important;
    left: 50% !important;
    transform: translateX(-50%);
  }
}
</style>
