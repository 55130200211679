import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import auth from './plugins/auth'
import http from './plugins/http'
import acl from './plugins/acl'
import vueDebounce from 'vue-debounce'
import { VueMaskDirective, VueMaskFilter } from 'v-mask'
import VueExcelXlsx from "vue-excel-xlsx";

Vue.use(VueExcelXlsx);

Vue.config.productionTip = false

Vue.use(vueDebounce, {
  listenTo: 'input',
  defaultTime: '700ms'
})

new Vue({
  router,
  store,
  vuetify,
  auth,
  http,
  acl,
  render: h => h(App),
}).$mount('#app')

Vue.directive('mask', VueMaskDirective)
Vue.filter('VMask', VueMaskFilter)

Vue.mixin({
  computed: {
    isMobile() {
      return window.innerWidth <= 600
    },
  },
})
