export default {

    namespaced: true,
  
    state: () => ({
  
      notifications: [],

    }),
  
    mutations: {
        setNotification(state, data) {
        state.notifications.push(data)
      },
    },
  
  }
  