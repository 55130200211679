import {
  mdiHomeOutline,
  mdiPrinterPos,
  mdiShieldAccountVariant,
  mdiShopping,
  mdiAccountMultipleOutline,
  mdiFileOutline,
  mdiReceipt,
  mdiDolly,
  mdiCurrencyUsd,
  mdiBoxCutter,
  mdiBoxingGlove,
  mdiArchive,
  mdiCartVariant,
  mdiFilePdfBox,
  mdiCalendarAlert,
  mdiFrequentlyAskedQuestions,
  mdiConnection,
  mdiTruck,
} from "@mdi/js";

export default [
  {
    title: "Dashboards",
    icon: mdiHomeOutline,
    badge: "3",
    badgeColor: "warning",
    children: [
      {
        title: "Painel Analitico",
        to: "dashboard",
      },
    ],
  },

  {
    subheader: "MENU",
  },
  {
    title: "Cadastros",
    icon: mdiArchive,
    children: [
      {
        title: "Produtos",
        to: "products",
      },
      {
        title: "Clientes",
        to: "consumers",
      },
      {
        title: "Fornecedores",
        to: "suppliers",
      },
    ],
  },
  {
    title: "Notas Fiscais",
    icon: mdiReceipt,

    children: [
      {
        title: "Serviços",
        to: "outgoing-invoices",
      },
      {
        title: "Produtos",
        to: "products-invoices",
      },
      {
        title: "Entradas",
        to: "incoming-invoices",
      },
    ],
  },
  {
    title: "Financeiro",
    icon: mdiCurrencyUsd,

    children: [
      {
        title: "Contas",
        to: "accounts",
      },
      {
        title: "Lancamentos",
        to: "cash-moviments",
      },
      // {
      //   title: "Fluxo de Caixa",
      //   badge: "Em breve",
      //   badgeColor: "success",
      //   to: "cash-flow",
      // },
    ],
  },
  {
    title: "Vendas",
    icon: mdiCartVariant,
    to: "orders",
  },
  {
    title: "Documentos",
    icon: mdiFilePdfBox,
    to: "documents",
  },
  {
    title: "Agenda",
    icon: mdiCalendarAlert,
    to: "calendar",
    badgeColor: "success",
  },
  {
    title: "Integrações",
    icon: mdiConnection,
    to: "integrations",
  },
  {
    subheader: "CONFIGURACOES",
  },

  {
    title: "Administração",
    icon: mdiShieldAccountVariant,
    children: [
      {
        title: "Minha Empresa",
        to: "my-company",
      },
      {
        title: "Usuários",
        to: "users",
      },
    ],
  },

  {
    title: "Transporte",
    icon: mdiTruck,
    children: [
      {
        title: "Logísticas",
        to: "logistics",
      },
      {
        title: "Galpões",
        to: "storages",
      },
      {
        title: "Pacotes",
        to: "packages",
      },
    ],
  },

  {
    title: "FAQ",
    icon: mdiFrequentlyAskedQuestions,
    to: "faq",
    badgeColor: "success",
  },
];
