<template>
  <v-layout>
    <v-dialog v-model="dialog" persistent max-width="600">
      <v-card>
        <v-card-title class="font-weight-bold text-button text-xl">
          Atenção!
        </v-card-title>
         <v-card-text v-if="hasSignature === true && hasContract === true">
          <span class="font-weight-bold text-button">
            Olá Bem vindo ao Ezcont, estamos atualizando para versão 2.0
            se você estiver com problemas com o sistema, por favor entre em contato com o suporte.
            Se você usa a versão 1.0, em breve todas as suas informações serão migradas.
          </span>
        </v-card-text>

        <v-card-text v-if="hasSignature === false && hasContract !== false">
          <span class="font-weight-bold text-button">
            Você tem um plano contratado na plataforma mais ainda não assinou o
            contrato por favor assine para continuar
          </span>
        </v-card-text>

        <v-card-text v-if="hasContract === false">
          <span class="font-weight-bold text-button"
            >Você ainda não tem contrato, antes de continuar por favor deve
            clicar no botão de gerar contrato</span
          >
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="mt-2">
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="navigateToExit">
            Voltar versão 1.0
          </v-btn>
          <v-btn
            v-if="hasContract === true && hasSignature === true"
            color="success"
            @click="dialog = false"
          >
            Continuar
          </v-btn>
          <v-btn
            v-if="hasContract === false"
            color="success"
            @click="createContract()"
          >
            Gerar Contrato
          </v-btn>
          <v-btn
            v-if="hasSignature === false && hasContract !== false"
            color="success"
            @click.stop
            :href="signContract()"
            target="_blank"
          >
            Assinar Contrato
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script>
export default {
  data() {
    return {
      dialog: true,
    };
  },

  created() {
    this.dialog = true;
  },

  computed: {
    hasContract() {
      return this.$store.getters["auth/hasContract"];
    },

    hasSignature() {
      if(this.hasContract && this.$store.state.auth.user.company.contract.has_signature == true) {
        return true;
      }

      return false;
    },
  },

  methods: {
    close() {
      this.dialog = false;
    },

    navigateToExit() {
      window.location.href = "https://app2.ezcont.com/";
      this.dialog = false;
    },

    createContract() {
      this.$store.dispatch("company/createContract", {
        tenant_id: this.$store.state.auth.user.company.tenant_id,
      });
      this.dialog = false;
    },

    signContract() {
      let name = this.$store.state.auth.user.user.name.replace(/ .*/, ""); 
      let company = this.$store.state.auth.user.user.email;
      let link = "https://api.whatsapp.com/send?phone=+5533998032347"
      let msg = "&text=Olá,Tudo Bem? meu nome é " + name + " meu email " + company + " Já criei meu contrato e estou pronto para assinar.";
      return link + msg;
    },
  },
};
</script>
