import store from '../store'

const acl = {

  can(permission) {
    if (this.isSuperAdmin()) {
      return true
    }

    return this.hasPermission(permission)
  },

  hasPermission(permission) {
    let permissionArr = this.getPermission(permission)

    if (permissionArr) {
        return true
    }

    return false
  },


  getPermission(permission) {
    try {
      return store.state.auth.user.user.permissions.filter((row) => row.name == permission)[0]
    } catch (e) {
      return false
    }
  },

  isSuperAdmin() {
    return store.state.auth.user.user.super_admin == 1
  },

}

export default acl;
