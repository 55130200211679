import acl from './../auth/Acl'
import Vue from 'vue';

Vue.use({
  install (Vue) {
    Vue.prototype.$acl = acl
    Vue.$acl = acl;
  }
})

export default (_) => { }
